import React from 'react';
import superagent from 'superagent';
import {withRouter} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './Search.css';

const COURSELIST_API = "/api/courselist";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.getAutocomplete = this.getAutocomplete.bind(this);
    this.handleAutocompleteOpen = this.handleAutocompleteOpen.bind(this);
    this.textChange = this.textChange.bind(this);
    this.search = this.search.bind(this);
    this.state = {
      searchText: "",
      courselist: this.getAutocomplete(),
      autocompleteOpen: false,
    };
  }

  textChange(e) {
    this.setState({
      searchText: e.target.value,
      autocompleteOpen: e.target.value.length > 3,
    });
  }

  search(e) {
    e.preventDefault();
    if (this.state.searchText) {
      this.props.history.push("/search/" + this.state.searchText);
    }
  }

  getAutocomplete() {
    // Check localstorage
    // TODO: Add expiration date to storage.
    let local = JSON.parse(localStorage.getItem("courselist"))
    if (local) return local.list;
    superagent
      .get(COURSELIST_API)
      .set('Accept', 'application/json')
      .then(resp => {
        let list = resp.body.results;
        this.setState({courselist: list});
        localStorage.setItem("courselist", JSON.stringify({list}));
      })
      .catch(err => {console.log("Could not fetch courselist: " + err)}); 
    return [];
  }

  handleAutocompleteOpen() {
    if (this.state.searchText.length > 3) {
      this.setState({"autocompleteOpen": true});
    } else {
      this.setState({"autocompleteOpen": false});
    }
  }

  render() {
    return (
      <Paper className="search-paper" elevation={1}>
        <Autocomplete
          freeSolo
          //open={this.state.autocompleteOpen}
          open={false}
          onOpen={this.handleAutocompleteOpen}
          options={this.state.courselist}
          renderInput={(params) => (
            <form className="search-paper" onSubmit={this.search}>
              <InputBase
                {...params.InputProps}
                inputProps={params.inputProps}
                placeholder="Module Code"
                className="search-input"
                onChange={this.textChange}
              />
              <IconButton aria-label="Search" type="submit">
                <SearchIcon />
              </IconButton>
            </form>)}
        />
      </Paper>
    );
  }
}

export default withRouter(Search);
